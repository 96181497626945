.bootStrapTable {
    padding: 2%
}
td.email-row {
    overflow: overlay;
    color: #003e80;
}
td.aaccount-row {
    word-break: break-all;
}
.accountmodal:hover {
    color: rgb(25, 25, 190);
}
.accountmodal {
    color: #007bff;
    cursor: pointer;
}
.estateLeadDetailsClass{
    word-break: break-all
}
p.estateLeadTag {
    text-align: center;
    color: firebrick;
}
.custom-row-class{
    cursor: pointer;
}
.buttonsForModal {
    justify-content: space-between;
    display: flex;
}