.backCard{
    background-color: #1f2b29
}
.fit-content{
    width: fit-content
}
.confirmpasswordclass {
    text-align: center;
     font-size: medium;
     color: whitesmoke;
     margin-top: 10px

}