
:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
  }
  
  .transparentSignIn {
    background-color: transparent;
   
}
  .card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
  
  .card-signin .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
  }
  
  .card-signin .card-body {
    padding: 2rem;
  }
  
  .form-signin {
    width: 100%;
  }
  
  .form-signin .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .form-label-group input {
    height: auto;
    border-radius: 2rem;
  }
  
  .form-label-group>input,
  .form-label-group>label {
    padding: .75rem 1.5rem;
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  
  .form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
  }
  
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  
  .form-label-group input::placeholder {
    color: transparent;
  }
  
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(.75rem + .75rem * (2 / 3));
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  
  .form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(.75rem / 3);
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
  }
  
  .btn-google {
    color: white;
    background-color: #ea4335;
  }
  
  .btn-facebook {
    color: white;
    background-color: #3b5998;
  }
  .imagePreview {
    text-align: center;
    margin-bottom: 5%;
}
  
  @supports (-ms-ime-align: auto) {
    .form-label-group>label {
      display: none;
    }
    .form-label-group input::-ms-input-placeholder {
      color: #777;
    }
  }
  
  
  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    .form-label-group>label {
      display: none;
    }
    .form-label-group input:-ms-input-placeholder {
      color: #777;
    }
  }
.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    cursor: default;
}
.fit-content{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}
 .emailclass{
     text-align: center;
     font-size: medium;
     color: whitesmoke;
 }
 .passwordclass{
    text-align: center;
    font-size: medium;
    color: whitesmoke;
    margin-top: 5%;
}
.card {
    background-color: #1f2b29;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    border-radius: 50%;
}

.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.form-signin #inputEmail,
.form-signin #inputPassword {
    direction: ltr;
    /* height: 44px; */
    font-size: 16px;
}



.btn.btn-signin {
    background-color: rgb(42, 67, 180);
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 30px;
    border-radius: 3px;
    border: none;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    margin-top: 5%;
    width: 36%;
    margin-left: 31%;
}
.hcenter{
    text-align: center;
    color: papayawhip;
}
.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: rgb(30, 43, 163);
}

.forgot-password {
    color: rgb(104, 145, 162);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: rgb(12, 97, 33);
}
.bootStrapTable {
    padding: 2%
}
td.email-row {
    overflow: overlay;
    color: #003e80;
}
td.aaccount-row {
    word-break: break-all;
}
.accountmodal:hover {
    color: rgb(25, 25, 190);
}
.accountmodal {
    color: #007bff;
    cursor: pointer;
}
.estateLeadDetailsClass{
    word-break: break-all
}
p.estateLeadTag {
    text-align: center;
    color: firebrick;
}
.custom-row-class{
    cursor: pointer;
}
.buttonsForModal {
    justify-content: space-between;
    display: flex;
}
a.nav-item.secondnav.nav-link{
    color: aliceblue
}
.backCard{
    background-color: #1f2b29
}
.fit-content{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}
.confirmpasswordclass {
    text-align: center;
     font-size: medium;
     color: whitesmoke;
     margin-top: 10px

}
